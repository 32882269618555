import React from "react";
import Card from "components/Card";
import {Label} from "components/Text";
import useInvestor from "hooks/useInvestor";
import toBTC from "utils/toBTC";
import moment from "moment";
import {Grid, Box} from "@chakra-ui/core";
import {
  Container,
  GridTable,
  GridTableRow,
  GridTableCell
} from "components/GridTable";
import Stat from "components/Stat";

const labels = {
  balanceSum: "Comission",
  initialInvestmentSum: "Investments"
};

function Summary({records, ...rest}) {
  const combinedSummary = records.reduce(
    (mem, item) => {
      mem.initialInvestmentSum += item.initialInvestment;
      mem.balanceSum += item.balance;
      return mem;
    },
    {
      balanceSum: 0,
      initialInvestmentSum: 0
    }
  );
  return (
    <Box
      p={0}
      py={[0, 0, 5]}
      px={[5, 5, 0]}
      bg="white"
      borderBottomColor="gray.100"
      borderBottomWidth="1px"
      borderRadius="lg"
      {...rest}
    >
      <Grid
        templateColumns={["1fr", "1fr", `repeat(2, 1fr)`]}
        gap="1px"
        bg="gray.100"
      >
        {Object.keys(combinedSummary).map(key => (
          <Box bg="white" px={[0, 0, 5]} py={[5, 5, 0]} key={key}>
            <Label mb={1}>{labels[key]}</Label>
            <Stat type="btc" value={combinedSummary[key]} />
          </Box>
        ))}
      </Grid>
    </Box>
  );
}

function ActiveReferers() {
  const {referers, getTransactionPerformanceSummary} = useInvestor();

  const refererSummaries = referers.map(t => ({
    transaction: t,
    ...getTransactionPerformanceSummary(t)
  }));

  return (
    <>
      <Label mb={1}>Active referers</Label>
      <Card p={0}>
        <Summary records={refererSummaries} />
        <Container borderRadius="lg">
          <GridTable templateColumns="auto 1fr 1fr" minWidth="610px">
            <GridTableRow
              borderBottomColor="gray.100"
              borderBottomWidth="1px"
              position="sticky"
              top="0px"
              bg="white"
              px={5}
              pt={4}
              pb={3}
            >
              <Label textAlign="left">Return</Label>
              <Label textAlign="right">Deposit</Label>
              <Label textAlign="right">Deposit Date</Label>
            </GridTableRow>
            {refererSummaries.map((record, index) => (
              <GridTableRow
                key={record.transaction.id}
                bg={index % 2 !== 0 ? "white" : "gray.50"}
                px={5}
                py={3}
              >
                <GridTableCell>{toBTC(record.balance)} BTC</GridTableCell>
                <GridTableCell>
                  {toBTC(record.initialInvestment)} BTC
                </GridTableCell>
                <GridTableCell>
                  {moment(record.transaction.depositedAt)
                    .utc()
                    .format("DD MMM YYYY")}
                </GridTableCell>
              </GridTableRow>
            ))}
          </GridTable>
        </Container>
      </Card>
    </>
  );
}

export default ActiveReferers;
