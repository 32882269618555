import {Box} from "@chakra-ui/core";
import styled from "@emotion/styled";

const Card = styled(Box)``;

Card.defaultProps = {
  bg: "white",
  borderRadius: "lg",
  boxShadow: 3,
  p: 6
};

export default Card;
