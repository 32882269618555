import React from "react";
import Investor from "utils/Investor";
import usePersistentState from "../hooks/usePersistentState";
import {useToast} from "@chakra-ui/core";
import {useParams} from "react-router-dom";

const investors = {};
const errors = {};

const syncToast = {
  title: "Sync in progress",
  description: "Fething latest data from blockchain",
  status: "info",
  duration: 3000
};

function useInvestor() {
  const rerender = React.useState()[1];
  const {investorAddress} = useParams();
  const [savedAddresses] = usePersistentState("addresses", []);
  const addresses = investorAddress ? [investorAddress] : savedAddresses;
  const key = JSON.stringify(addresses);
  const toast = useToast();

  // Subscribe to instance events and trigger re-render
  React.useEffect(() => {
    const unsubUpdate = investors[key]?.on("update", () => {
      errors[key] = null;
      rerender({});
    });
    const unsubError = investors[key].on("error", e => {
      errors[key] = e;
      rerender({});
    });

    return () => {
      // Cleanup
      unsubUpdate && unsubUpdate();
      unsubError && unsubError();
    };
  }, [key, rerender, toast]);

  // Initial instance creation
  if (!investors[key]) {
    let resolve = () => {};
    try {
      // Create instace for current addresses
      investors[key] = new Investor(addresses);

      // Subscribe for events and resolve/reject promise
      const unsubUpdate = investors[key].on("update", () => {
        errors[key] = null;
        resolve();
        unsubUpdate();
      });
      const unsubError = investors[key].on("error", e => {
        errors[key] = e;
        resolve();
        unsubError();
      });
      investors[key].on("init", () => {
        // Only display toast when instance is already initialized or cached
        if (investors[key].isInitialized || investors[key].isCached) {
          toast(syncToast);
        }
      });

      // Cached investor data was available current instance is already usable
      if (investors[key].isCached) {
        return investors[key];
      }
    } catch (e) {
      // Let the error boundary pick this up
      throw e;
    }
    // Suspense will catch this
    throw new Promise(_resolve => {
      resolve = _resolve;
    });
  } else if (errors[key]) {
    // Let the error boundary pick this up
    throw errors[key];
  }

  // Loaded instance is available and ready to use
  return investors[key];
}

export default useInvestor;
