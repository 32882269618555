import React from "react";
import {Switch, Route} from "react-router-dom";

import Fund from "pages/Fund";
import Investor from "pages/Investor";

function Router() {
  return (
    <Switch>
      <Route path="/investors/:investorAddress" component={Investor} />
      <Route path="/investor" component={Investor} />
      <Route path="/" component={Fund} />
    </Switch>
  );
}

export default Router;
