import moment from "moment";

export function groupByMonths(records = []) {
  const groupsObj = records.reduce((mem, item) => {
    const month = moment(item.date)
      .utc()
      .format("YYYY-MM");
    if (!mem[month]) {
      mem[month] = [item];
    } else {
      mem[month] = [...mem[month], item];
    }
    return mem;
  }, {});
  return Object.keys(groupsObj).map(key => groupsObj[key]);
}

export function getRecordsForLatestMonth(records = []) {
  const months = groupByMonths(records);
  const latestMonth = months?.[0];
  return latestMonth;
}

export function getCompoundFromRecords(key, records = []) {
  return records.reduce((mem, item) => {
    mem += item[key] + mem * item[key];
    return mem;
  }, 0);
}
