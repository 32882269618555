import React from "react";
import {Box, Alert, AlertIcon} from "@chakra-ui/core";
import {Link} from "react-router-dom";
import usePersistentState from "hooks/usePersistentState";

function Header(props) {
  const [addresses] = usePersistentState("addresses");
  return !addresses?.length ? (
    <Box {...props}>
      <Box cursor="pointer" as={Link} to="/investor">
        <Alert status="info" variant="left-accent" borderRadius="md">
          <AlertIcon />
          <Box>
            <b>Introducing Investor profiles</b>
            <br />
            Now you can even track your investment performance with us.{" "}
            <u>Learn more</u>
          </Box>
        </Alert>
      </Box>
    </Box>
  ) : null;
}

export default Header;
