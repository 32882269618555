import React from "react";
import {Alert, AlertIcon, AlertTitle, AlertDescription} from "@chakra-ui/core";
import Page from "components/Page";
import {withRouter} from "react-router-dom";

class ErrorBoundary extends React.Component {
  state = {error: null};
  static getDerivedStateFromError(error) {
    return {
      error
    };
  }
  componentDidUpdate({location: {key: oldKey}}) {
    const {
      location: {key}
    } = this.props;
    if (key !== oldKey) {
      this.setState({error: null});
    }
  }
  render() {
    if (this.state.error) {
      return (
        <Page>
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Woops!</AlertTitle>
            <AlertDescription>{this.state.error.message}</AlertDescription>
          </Alert>
        </Page>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
