import React from "react";

const customIcons = {
  "change-up": {
    path: (
      <path
        fill="currentColor"
        d="M12,1.207 L12,8.408 C12,9.0707417 11.4627417,9.608 10.8,9.608 C10.1372583,9.608 9.6,9.0707417 9.6,8.408 L9.6,4.09 L2.033,11.653 C1.56712539,12.1169104 0.813874607,12.1169104 0.348,11.653 C-0.115910366,11.1871254 -0.115910366,10.4338746 0.348,9.968 L7.914,2.4 L3.594,2.4 C2.9312583,2.4 2.394,1.8627417 2.394,1.2 C2.394,0.5372583 2.9312583,0 3.594,0 L10.797,0 C11.4617499,0.00384483521 11.9989123,0.543239853 12,1.208 L12,1.207 Z"
      />
    ),
    viewBox: "0 0 12 12"
  },
  "change-down": {
    path: (
      <path
        fill="currentColor"
        d="M11.9999328,1.207 L11.9999328,8.408 C11.9999328,9.0707417 11.4626745,9.608 10.7999328,9.608 C10.1371911,9.608 9.59993277,9.0707417 9.59993277,8.408 L9.59993277,4.09 L2.03293277,11.653 C1.56705817,12.1169104 0.813807381,12.1169104 0.347932775,11.653 C-0.115977592,11.1871254 -0.115977592,10.4338746 0.347932775,9.968 L7.91393277,2.4 L3.59393277,2.4 C2.93119107,2.4 2.39393277,1.8627417 2.39393277,1.2 C2.39393277,0.5372583 2.93119107,0 3.59393277,0 L10.7969328,0 C11.4616827,0.00384483521 11.9988451,0.543239853 11.9999328,1.208 L11.9999328,1.207 Z"
        transform="translate(5.999966, 6.000466) scale(1, -1) translate(-5.999966, -6.000466) "
      />
    ),
    viewBox: "0 0 12 12"
  },
  "no-change": {
    path: (
      <g transform="translate(11.000000, 10.000000)" fill="currentColor">
        <rect x="0" y="0" width="16" height="3" rx="1.5" />
        <rect x="0" y="7" width="16" height="3" rx="1.5" />
      </g>
    ),
    viewBox: "10 6 16 16"
  }
};
export default customIcons;
