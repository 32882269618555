import React from "react";
import {Flex, Image} from "@chakra-ui/core";
import {NavLink, Link} from "react-router-dom";
import styled from "@emotion/styled";
import Text from "./Text";

NavLink.defaultProps = {
  exact: true
};

const NavItem = styled(Text)`
  &.active {
    color: ${p => p?.theme?.colors?.blue?.["800"]};
    border-bottom: 3px solid ${p => p?.theme?.colors?.blue?.["800"]};
  }
`;

NavItem.defaultProps = {
  as: NavLink,
  px: 4,
  py: 3,
  fontWeight: 500,
  color: "gray.600",
  mx: [0, 4]
};

function Navbar(props) {
  return (
    <Flex
      bg="white"
      boxShadow={2}
      position="sticky"
      top="0"
      zIndex="200"
      justify="space-between"
      {...props}
    >
      <Link to="/">
        <Image
          height="50px"
          src={require("../assets/logo.png")}
          ml={2}
          display={["none", "block"]}
        />
      </Link>
      <Flex justify={["space-between", "flex-end"]} flex="1">
        <NavItem to="/" exact>
          Fund summary
        </NavItem>
        <NavItem to="/investor">Investor profile</NavItem>
      </Flex>
    </Flex>
  );
}

export default Navbar;
