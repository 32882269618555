import React from "react";
import {Tag, TagIcon, TagLabel} from "@chakra-ui/core";

function ChangeTag({change, ...rest}) {
  return (
    <Tag
      size="sm"
      variantColor={change < 0 ? "red" : change > 0 ? "green" : "orange"}
      justifyContent="space-between"
      minWidth="88px"
      {...rest}
    >
      <TagIcon
        icon={
          change < 0 ? "change-down" : change > 0 ? "change-up" : "no-change"
        }
        size="12px"
      />
      <TagLabel>
        {change > 0 ? "+" : change === 0 ? " " : ""}
        {(change * 100).toFixed(2)}%
      </TagLabel>
    </Tag>
  );
}

export default ChangeTag;
