import React from "react";
import {Box, Grid} from "@chakra-ui/core";
import Card from "components/Card";
import useInvestor from "hooks/useInvestor";
import {Label} from "components/Text";
import {groupByMonths} from "utils/records";
import Stat from "components/Stat";
import useAsync from "hooks/useAsync";

function Summary({records, ...rest}) {
  const {fundPerformance, getPerformanceSummary, amfeix} = useInvestor();
  const {changePercentCompound} = getPerformanceSummary(fundPerformance);
  const months = groupByMonths(fundPerformance);
  const yesterday = fundPerformance[fundPerformance.length - 1];
  const {data: aum} = useAsync({key: "aum", promise: amfeix.getAUM});

  const sumMonth = months
    .slice(-7)
    .slice(0, -1)
    .reduce((mem, item) => {
      const {changePercentCompound} = getPerformanceSummary(item);
      return mem + changePercentCompound;
    }, 0);
  const avgMonth = sumMonth / 6;

  const stats = [
    {
      label: "Yesterday",
      infoText: "Reported return of previous day",
      type: "percent",
      value: yesterday.changePercent
    },
    {
      label: "Monthly AVG",
      infoText: "Avarage monthly return based on the last 6 months",
      type: "percent",
      value: avgMonth
    },
    {
      label: "Total",
      type: "percent",
      infoText: "Compounded daily returns since the fund started",
      decimal: 0,
      value: changePercentCompound
    },
    {
      label: "AUM",
      infoText: "Assets under management",
      type: "btc",
      decimal: 0,
      value: aum
    }
  ];
  return (
    <>
      <Label mb={1}>Fund summary</Label>
      <Card
        p={0}
        py={[0, 0, 5]}
        px={[5, 5, 0]}
        bg="white"
        borderRadius="lg"
        {...rest}
      >
        <Grid
          templateColumns={["1fr", "1fr", `repeat(4, 1fr)`]}
          gap="1px"
          bg="gray.100"
        >
          {stats.map(({label, type, value, infoText, decimal}) => (
            <Box bg="white" px={[0, 0, 5]} py={[5, 5, 0]} key={label}>
              <Label infoText={infoText} mb={1}>
                {label}
              </Label>
              <Stat type={type} value={value} decimal={decimal} />
            </Box>
          ))}
        </Grid>
      </Card>
    </>
  );
}

export default Summary;
