import React from "react";
import Card from "components/Card";
import ChangeTag from "components/ChangeTag";
import {Label} from "components/Text";
import useInvestor from "hooks/useInvestor";
import useBitcoinPrice from "hooks/useBitcoinPrice";
import toBTC from "utils/toBTC";
import {Box, Grid} from "@chakra-ui/core";
import Stat from "components/Stat";

function Summary(props) {
  const {summary} = useInvestor();
  const price = useBitcoinPrice("usd");

  const stats = [
    {
      label: "Yesterday",
      changeBTC: summary.yesterdayChangeBTC,
      changePercent: summary.yesterdayChangePercent
    },
    {
      label: "Total",
      changeBTC: summary.totalChangeBTC,
      changePercent: summary.totalChangePercent
    }
  ];
  return (
    <Box {...props}>
      <Label mb={1}>Summary</Label>
      <Card p={0} py={[0, 0, 5]} px={[5, 5, 0]}>
        <Grid
          templateColumns={["1fr", "1fr", `repeat(${stats.length}, 1fr)`]}
          gap="1px"
          bg="gray.100"
        >
          {stats.map(({label, changeBTC, changePercent}) => (
            <Box bg="white" px={[0, 0, 5]} py={[5, 5, 0]} key={label}>
              <Label mb={1}>{label}</Label>
              <Stat type="btc" value={changeBTC} showPositive mb={2} />
              <Stat
                type="usd"
                value={toBTC(changeBTC) * price}
                color="gray.500"
                size="md"
                mb={3}
                showPositive
              />
              <ChangeTag change={changePercent} />
            </Box>
          ))}
        </Grid>
      </Card>
    </Box>
  );
}

export default Summary;
