import EventBus from "utils/EventBus";

function isLocalStorageAvailable() {
  try {
    localStorage.setItem("mod", "mod");
    localStorage.removeItem("mod");
    return true;
  } catch (e) {
    return false;
  }
}

class Storage extends EventBus {
  constructor({type = "memory", prefix = ""} = {}) {
    super();
    this.type = type;
    this.prefix = prefix;
    this.isLocalStorage =
      this.type === "localStorage" && isLocalStorageAvailable();
    if (this.isLocalStorage) {
      window.addEventListener("storage", ({key, newValue}) => {
        this.triggerEvent(key, newValue);
      });
    }
  }
  _data = {};
  getItem(key, defaultValue) {
    const prefixedKey = this.prefix ? `${this.prefix}-${key}` : key;
    if (this._data[prefixedKey]) {
      return this._data[prefixedKey];
    }
    if (this.isLocalStorage) {
      const jsonString = window.localStorage.getItem(prefixedKey);
      if (jsonString) {
        try {
          return JSON.parse(jsonString);
        } catch (e) {
          window.localStorage.removeItem(prefixedKey);
        }
      }
    }
    return defaultValue;
  }
  setItem(key, value) {
    const prefixedKey = this.prefix ? `${this.prefix}-${key}` : key;
    const strValue = JSON.stringify(value);
    if (this.isLocalStorage) {
      window.localStorage.setItem(prefixedKey, strValue);
    }
    const strCurrentValue = JSON.stringify(this._data[prefixedKey]);
    if (strCurrentValue !== strValue) {
      this._data[prefixedKey] = value;
      this.triggerEvent(prefixedKey, value);
      this.triggerEvent("set", {
        key: prefixedKey,
        value,
      });
    }
  }
}

export default Storage;
