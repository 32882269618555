import React from "react";
import {Box, ButtonGroup, Button, Image, Progress} from "@chakra-ui/core";
import Text from "components/Text";

function Stepper({steps, onStepChange = () => {}, ...rest}) {
  const [currentStep, setStep] = React.useState(0);
  function nextStep() {
    const newStep = Math.min(steps.length - 1, currentStep + 1);
    setStep(newStep);
    onStepChange(newStep);
  }
  function previousStep() {
    const newStep = Math.max(0, currentStep - 1);
    setStep(newStep);
    onStepChange(newStep);
  }
  const step = steps?.[currentStep];
  return (
    <Box {...rest}>
      <Progress
        borderRadius="md"
        mb={5}
        value={((currentStep + 1) / steps.length) * 100}
      />
      <Text fontSize="2xl">{step?.title}</Text>
      <Image my={5} src={step?.img} />
      <Text fontSize="md" lineHeight="1.5">
        {step?.infoText}
      </Text>
      <ButtonGroup
        mt={3}
        display="flex"
        justifyContent={["space-between", "flex-end"]}
        spacing={4}
      >
        {currentStep > 0 && <Button onClick={previousStep}>Back</Button>}
        {currentStep < steps.length - 1 && (
          <Button variantColor="blue" onClick={nextStep}>
            Next
          </Button>
        )}
      </ButtonGroup>
    </Box>
  );
}

export default Stepper;
