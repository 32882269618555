import React from "react";
import {Spinner, Flex} from "@chakra-ui/core";

function Loader({
  size = "100px",
  thickness = "3px",
  color = "gray.500",
  ...rest
}) {
  return (
    <Flex minHeight="50vh" align="center" justify="center" {...rest}>
      <Spinner size={size} thickness={thickness} color={color} />
    </Flex>
  );
}

export default Loader;
