import React from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  ButtonGroup,
  FormErrorMessage,
  Flex,
  Alert,
  AlertTitle,
  AlertIcon,
  AlertDescription
} from "@chakra-ui/core";
import {useForm, useFieldArray} from "react-hook-form";
import usePersistentState from "hooks/usePersistentState";

function ManageInvestorAddresses({preventRemoveLast, onSave = () => {}}) {
  const [addresses, setAddresses] = usePersistentState("addresses", []);
  const {control, handleSubmit, register, errors} = useForm({
    defaultValues: {addresses: addresses.length ? addresses : [""]},
    reValidateMode: "onBlur"
  });
  const {fields, append, remove} = useFieldArray({
    name: "addresses",
    control
  });
  const onSubmit = ({addresses = []}) => {
    onSave();
    setAddresses(addresses);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, index) => (
        <FormControl
          isInvalid={errors.addresses?.[index]}
          key={field.id}
          mb={5}
        >
          <Flex justify="space-between">
            <FormLabel htmlFor={`addresses[${index}]`}>
              Investor address {index + 1}
            </FormLabel>
            {(fields.length > 1 || !preventRemoveLast) && (
              <Button
                variant="outline"
                size="xs"
                leftIcon="delete"
                onClick={() => remove(index)}
              >
                Remove
              </Button>
            )}
          </Flex>
          <Input
            type="text"
            id={`addresses[${index}]`}
            aria-describedby="investor-address"
            name={`addresses[${index}]`}
            placeholder="0x7a5e4971c35C2bcAafEE2C7E83D8dD0CAabbCdeA"
            ref={register({
              required: "Required",
              validate: value => {
                return /^0x[a-fA-F0-9]{40}$/g.test(value)
                  ? true
                  : "Invalid investor address format";
              }
            })}
          />
          {errors.addresses?.[index] ? (
            <FormErrorMessage>
              {errors.addresses[index].message}
            </FormErrorMessage>
          ) : (
            <FormHelperText id="investor-address-helper-text">
              Investor addresses are public by design as they are stored on the
              blockchain and are acceessable by anyone.
            </FormHelperText>
          )}
        </FormControl>
      ))}
      {fields.length === 0 ? (
        <Alert
          status="warning"
          variant="left-accent"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          minHeight="200px"
        >
          <AlertIcon size="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            You are about to remove your profile.
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            If you proceed your previously saved addresses will be removed and
            you will need to add them again to keep tracking your investments.
          </AlertDescription>
        </Alert>
      ) : (
        <Button
          variant="outline"
          variantColor="blue"
          size="sm"
          leftIcon="add"
          onClick={() => append()}
        >
          Add investor address {fields.length + 1}
        </Button>
      )}
      <ButtonGroup
        mt={6}
        display="flex"
        justifyContent={["flex-start", "flex-end"]}
        spacing={4}
      >
        {fields.length === 0 && (
          <Button onClick={() => append()} variant="outline">
            Cancel
          </Button>
        )}
        <Button type="submit" variantColor={fields.length > 0 ? "blue" : "red"}>
          {fields.length > 0 ? "Save addresses" : "Remove profile"}
        </Button>
      </ButtonGroup>
    </form>
  );
}

export default ManageInvestorAddresses;
