import React from "react";
import {Stack} from "@chakra-ui/core";
import Header from "./Header";
import {useParams} from "react-router-dom";
import Summary from "./Summary";
import ActiveInvestments from "./ActiveInvestments";
import ActiveReferers from "./ActiveReferers";
import usePersistentState from "hooks/usePersistentState";
import Onboarding from "./Onboarding";
import ManageAddressesModal from "./ManageAddressesModal";
import Months from "./Months";

function Investor() {
  const [savedAddresses] = usePersistentState("addresses");
  const {investorAddress} = useParams();
  const addresses = investorAddress ? [investorAddress] : savedAddresses;
  return addresses?.length ? (
    <Stack shouldWrapChildren direction="column" spacing="60px">
      <Header />
      <Summary />
      <Months />
      <ActiveInvestments />
      <ActiveReferers />
      {savedAddresses?.length && <ManageAddressesModal />}
    </Stack>
  ) : (
    <Onboarding />
  );
}

export default Investor;
