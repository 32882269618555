import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Button
} from "@chakra-ui/core";
import ManageInvestorAddresses from "components/forms/ManageInvestorAddresses";

function ManageAddressesModal(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  function handleSave() {
    onClose();
  }

  return (
    <Box {...props}>
      <Button
        display="block"
        ml="auto"
        variant="outline"
        variantColor="blue"
        onClick={onOpen}
        width={["100%", "unset"]}
      >
        Manage addresses
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Investor addresses</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <ManageInvestorAddresses onSave={handleSave} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ManageAddressesModal;
