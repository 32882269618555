import React from "react";
import {Stack, Button, Box} from "@chakra-ui/core";
import {groupByMonths} from "utils/records";
import Month from "./Month";
import useInvestor from "hooks/useInvestor";

function Months() {
  const {performance} = useInvestor();
  const [showRestOfMonths, setShowRestOfMonths] = React.useState(false);
  const months = groupByMonths(performance);
  const visibleRestOfmonths = showRestOfMonths ? months.slice(1) : [];

  return (
    <Box>
      <Stack shouldWrapChildren direction="column" spacing="60px">
        <Month records={months[0]} />
        {visibleRestOfmonths.map((month, index) => (
          <Month records={month} key={index} />
        ))}
      </Stack>
      <Button
        variant="outline"
        variantColor="blue"
        onClick={() => setShowRestOfMonths(!showRestOfMonths)}
        width={["100%", "auto"]}
        mt={5}
      >
        {showRestOfMonths ? "Hide previous months" : "Show previous months"}
      </Button>
    </Box>
  );
}

export default Months;
