import Storage from "utils/Storage";
import hash from "utils/hash";
import amfeix from "./amfeix";
import EventBus from "./EventBus";
import {getRecordsForLatestMonth} from "./records";

class Investor extends EventBus {
  constructor(addresses = []) {
    super();
    this.addresses = addresses;
    this.store = new Storage({
      type: "localStorage",
      prefix: hash(addresses)
    });
    this.amfeix = amfeix;

    // Subscribe to store 'set' events and trigger 'updated' event to
    // instance listeners
    this.store.on("set", () => {
      this.triggerEvent("update");
    });
    // Cached investor data available
    const cachedFundPerformance = this.fundPerformance;
    const cachedTransactions = this.transactions;
    if (cachedFundPerformance && cachedTransactions) {
      this.isCached = true;
      this.triggerEvent("update");
    }

    // Trigger data fetching / cache invalidation
    // setTimeout is used to allow instance event subscriptions to fire on syncronus cases
    setTimeout(() => {
      this.init();
    }, 0);
  }
  init = async () => {
    try {
      this.triggerEvent("init");
      const [fundPerformance, transactions] = await Promise.all([
        amfeix.getFundDailyPerformance(),
        this.getTransactions()
      ]);
      this.store.setItem("fundPerformance", fundPerformance);
      this.store.setItem("transactions", transactions);
      this.isInitialized = true;
    } catch (e) {
      this.triggerEvent("error", e);
    }
  };
  getTransactions = async () => {
    try {
      const promises = this.addresses.map(address =>
        amfeix.getInvestorTransactions(address)
      );
      const transactionsByAddress = await Promise.all(promises);
      return transactionsByAddress.reduce((mem, item) => {
        return [...mem, ...item];
      }, []);
    } catch (e) {
      this.triggerEvent("error", e);
    }
  };
  getPerformanceSummary = (records = []) =>
    amfeix.getPerformanceSummary(records);
  getTransactionPerformanceSummary = transaction => {
    const performance = amfeix.getTransactionPerformance(
      transaction,
      this.fundPerformance
    );
    const {balance} = performance?.[performance.length - 1] || {};
    return {
      balance,
      initialInvestment: transaction.initialValue,
      ...amfeix.getPerformanceSummary(performance)
    };
  };
  get fundPerformance() {
    return this.store.getItem("fundPerformance", null);
  }
  get transactions() {
    return this.store.getItem("transactions", null);
  }
  get investments() {
    return this.store
      .getItem("transactions", [])
      .filter(t => !t.referer && !t.withdrawnAt);
  }
  get referers() {
    return this.store
      .getItem("transactions", [])
      .filter(t => t.referer && !t.withdrawnAt);
  }
  get performance() {
    return amfeix
      .getTransactionsPerformance(
        [...this.investments, ...this.referers],
        this.fundPerformance
      )
      .reverse();
  }
  get investmentsPerformance() {
    return amfeix
      .getTransactionsPerformance(this.investments, this.fundPerformance)
      .reverse();
  }
  get referersPerformance() {
    return amfeix
      .getTransactionsPerformance(this.referers, this.fundPerformance)
      .reverse();
  }
  get summary() {
    const {
      balance: investmentsBalance = 0,
      initialValue = 0,
      changePercent: yesterdayChangePercent = 0,
      changeBTC: yesterdayChangeBTC = 0
    } = this.investmentsPerformance?.[0] || {};
    const {balance: referersBalance = 0} = this.referersPerformance?.[0] || {};
    const totalBalance = investmentsBalance + referersBalance;
    const totalChangeBTC = totalBalance - initialValue;
    const totalChangePercent = totalChangeBTC / initialValue || 0;
    const {
      changePercentCompound: currentMonthChangePercent,
      changeBTC: currentMonthChangeBTC
    } = this.getPerformanceSummary(getRecordsForLatestMonth(this.performance));
    return {
      totalBalance,
      investmentsBalance,
      initialInvestment: initialValue,
      referersBalance,
      totalChangeBTC,
      totalChangePercent,
      yesterdayChangeBTC,
      yesterdayChangePercent,
      currentMonthChangeBTC,
      currentMonthChangePercent
    };
  }
}

export default Investor;
