import React from "react";
import Text from "components/Text";
import toBTC from "utils/toBTC";
import {format} from "utils/currency";
import {Flex, Icon} from "@chakra-ui/core";
import styled from "@emotion/styled";

const Value = styled(Text)`
  font-variant-numeric: tabular-nums;
`;

const suffix = {
  percent: "%",
  btc: "BTC",
  usd: "USD"
};

const sizes = {
  sm: ["sm", "xs"],
  md: ["md", "xs"],
  lg: ["2xl", "lg"],
  xl: ["4xl", "2xl"]
};

const iconSizes = {
  sm: "8px",
  md: "10px",
  lg: "12px",
  xl: "14px"
};

function Stat({
  type,
  value,
  size = "lg",
  color = "gray.700",
  showPositive = false,
  decimal,
  ...rest
}) {
  const [sizeNormal, sizeSmaller] = sizes[size] || sizes["md"];
  function getValue() {
    switch (type) {
      case "percent":
        return (value * 100).toFixed(decimal !== undefined ? decimal : 2);
      case "btc":
        return toBTC(value, decimal !== undefined ? decimal : 6);
      case "usd":
        return format(value);
      default:
        return value;
    }
  }
  function getPercentIconProps() {
    const commons = {
      mr: 2,
      size: iconSizes[size] || iconSizes["md"]
    };
    if (value < 0) {
      return {
        ...commons,
        name: "change-down",
        color: "red.500"
      };
    } else if (value > 0) {
      return {
        ...commons,
        name: "change-up",
        color: "green.500"
      };
    }
    return {
      ...commons,
      name: "no-change",
      color: "yellow.500"
    };
  }
  return (
    <Flex align="baseline" color={color} {...rest}>
      {type === "percent" && <Icon {...getPercentIconProps()} />}
      <Value fontSize={sizeNormal} mr={1}>
        {showPositive && value > 0 && "+"}
        {getValue()}
      </Value>
      <Text fontSize={sizeSmaller} color={color} fontWeight="500">
        {suffix[type] || type}
      </Text>
    </Flex>
  );
}

export default Stat;
