class EventBus {
  listeners = {};

  listenerLastID = 0;

  listenerUnsubscriber(listenerID) {
    delete this.listeners[listenerID];
  }

  on(topic, callback) {
    this.listenerLastID += 1;
    const listenerID = this.listenerLastID;
    this.listeners[listenerID] = {topic, callback};

    return () => this.listenerUnsubscriber(listenerID);
  }

  triggerEvent(topic, event) {
    Object.keys(this.listeners).forEach((listenerID) => {
      const currentListener = this.listeners[listenerID];
      if (currentListener && topic === currentListener.topic)
        currentListener.callback(event);
    });
  }

  getListenersByTopic(topic) {
    return Object.keys(this.listeners).filter(
      (key) => this.listeners[key].topic === topic
    );
  }
}

export default EventBus;
