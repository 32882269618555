import React from "react";
import Storage from "utils/Storage";

const storage = new Storage({type: "localStorage"});

function usePersistentState(key, defaultValue) {
  const rerender = React.useState()[1];

  React.useEffect(() => {
    const unsubscribe = storage.on(key, () => {
      rerender({});
    });
    return () => {
      unsubscribe();
    };
  }, [key, rerender]);

  const setState = React.useCallback(value => storage.setItem(key, value), [
    key
  ]);

  return [storage.getItem(key, defaultValue), setState];
}

export default usePersistentState;
