import React from "react";
import {useHistory} from "react-router-dom";

function ScrollToTop() {
  const history = useHistory();
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}

export default ScrollToTop;
