import React, {Suspense} from "react";
import {ThemeProvider, CSSReset} from "@chakra-ui/core";
import {BrowserRouter} from "react-router-dom";
import theme from "../theme";

import ErrorBoundary from "components/ErrorBoundary";
import Router from "components/Router";
import Loader from "./Loader";
import Page from "./Page";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <CSSReset />
        <ErrorBoundary>
          <Page>
            <Suspense fallback={<Loader />}>
              <Router />
            </Suspense>
          </Page>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
