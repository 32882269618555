import React from "react";
import axios from "axios";
import Storage from "utils/Storage";
import useAsync from "./useAsync";

const storage = new Storage({
  type: "localStorage"
});

const timers = {};
const listeners = {};

const useFetch = (endpoint, {refreshInterval = 0} = {}) => {
  const key = `fetch-${endpoint}`;

  const {data, revalidate} = useAsync({
    key,
    promise: axios.get,
    args: [endpoint],
    cache: storage
  });

  // Polling magic
  React.useEffect(() => {
    // Keep track of listeners
    listeners[key] = listeners[key] ? listeners[key]++ : 1;

    // Setup new revalidate interval
    if (refreshInterval > 0 && !timers[key]) {
      timers[key] = setInterval(revalidate, refreshInterval);
    }

    // Cleanup
    return () => {
      --listeners[key];
      // Stop polling if there are no more listeners left
      if (listeners[key] === 0) {
        clearInterval(timers[key]);
      }
    };
  }, [key, refreshInterval, revalidate]);

  return {data, revalidate};
};

export default useFetch;
