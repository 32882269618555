import React from "react";
import Base from "components/Text";
import Card from "components/Card";
import {
  Stack,
  Image,
  Button,
  ButtonGroup,
  Icon,
  Flex,
  Alert,
  AlertIcon
} from "@chakra-ui/core";
import styled from "@emotion/styled";
import ManageInvestorAddresses from "components/forms/ManageInvestorAddresses";
import {Link} from "react-router-dom";
import Stepper from "components/Stepper";

const Text = styled(Base)``;

Text.defaultProps = {
  width: "100%"
};

const steps = [
  {
    title: "Step 1: Login",
    infoText: (
      <span>
        First things first you need to login to your{" "}
        <a
          href="https://amfeixportal.com/platform/unlock"
          target="_blank"
          rel="noopener noreferrer"
          style={{textDecoration: "underline"}}
        >
          amfeix account
        </a>
        .
      </span>
    ),
    img: require("assets/step1.png")
  },
  {
    title: "Step 2: AMFEIX Fund",
    infoText: `Choose "AMFEIX fund" from the left menu and scroll to the bottom of the screen. Click any of the "Withdraw" button to bring up the next dialog. Don't worry we are not asking to actually withdraw any funds.`,
    img: require("assets/step2.png")
  },
  {
    title: `Step 3: Choose the "Advanced Tab"`,
    infoText:
      "You are almost done, just click on the advanced tab dropdown to bring up your investor address.",
    img: require("assets/step3.png")
  },
  {
    title: "Step 4: Copy the address",
    infoText:
      "Select your investor address, copy and paste it below! PRO TIP: Repeate this step on all your account if you have more then one.",
    img: require("assets/step4.png")
  }
];

const benefits = [
  "Get great reporting",
  "Track multiple accounts and see combined balances",
  "Privacy first! All data is stored locally in your browser",
  "Fast access, no login required"
];

function Onboarding() {
  const [currentStep, setStep] = React.useState(0);
  const [currentTutorialStep, setTutorialStep] = React.useState(0);
  function handleTutorialStepChange(index) {
    setTutorialStep(index);
  }

  function handleSave() {
    window.scrollTo(0, 0);
  }

  switch (currentStep) {
    case 1:
      return (
        <>
          <Text fontSize={["2xl", "4xl"]} mb={4}>
            Creating your profile is easy!
          </Text>
          <Alert
            status="success"
            variant="left-accent"
            borderRadius="md"
            mb={4}
          >
            <AlertIcon />
            Privacy first, we would never ask for your seed keys!
          </Alert>
          <Text mb={3}>
            To create an account on this site you will need to enter your AMFEIX
            investor address. Don't worry this address is harmless and it's
            completley useless by anyone else.
          </Text>
          <Text mb={5}>
            Follow these few steps below and you'll see, how easy is to find it
            on the AMFEIX website.
          </Text>
          <Card mb={5}>
            <Stepper steps={steps} onStepChange={handleTutorialStepChange} />
          </Card>
          {currentTutorialStep === steps.length - 1 && (
            <Card>
              <Stack shouldWrapChildren spacing={5}>
                <ManageInvestorAddresses
                  preventRemoveLast
                  onSave={handleSave}
                />
              </Stack>
            </Card>
          )}
        </>
      );
    default:
      return (
        <>
          <Text fontSize={["2xl", "4xl"]} mb={4}>
            Easily track your investments
          </Text>
          <Card>
            <Stack shouldWrapChildren spacing={5}>
              <Stack shouldWrapChildren spacing={3}>
                {benefits.map(b => (
                  <Flex align="center" key={b}>
                    <Icon name="check-circle" color="green.400" mr={3} />
                    <Text>{b}</Text>
                  </Flex>
                ))}
              </Stack>
              <Text>
                It's super easy to set it up and the best thing is that you{" "}
                <b>don't need to register</b> and you{" "}
                <b>don't have to to give us access</b> to your Amfeix account!
              </Text>
              <Text>Sounds good, right? It is!</Text>
              <Image
                src={require("assets/investor-account.png")}
                borderRadius="md"
                boxShadow={2}
              />
              <ButtonGroup
                display="flex"
                justifyContent={["space-between", "flex-end"]}
                spacing={4}
              >
                <Button
                  as={Link}
                  to={`/investors/0x7a5e4971c35C2bcAafEE2C7E83D8dD0CAabbCdeA`}
                >
                  View demo
                </Button>
                <Button variantColor="blue" onClick={() => setStep(1)}>
                  Let's get started
                </Button>
              </ButtonGroup>
            </Stack>
          </Card>
        </>
      );
  }
}

export default Onboarding;
