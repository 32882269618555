import React from "react";
import {Flex, Image, Box} from "@chakra-ui/core";
import Card from "components/Card";
import useBitcoinPrice from "hooks/useBitcoinPrice";
import useInvestor from "hooks/useInvestor";
import toBTC from "utils/toBTC";
import {format} from "utils/currency";
import {Label} from "components/Text";
import Stat from "components/Stat";

function Header() {
  const {summary} = useInvestor();
  const price = useBitcoinPrice("usd");
  return (
    <Flex
      direction={["column", "row"]}
      align={["stretch", "space-between"]}
      mt={[0, "30px"]}
    >
      <Image
        src={require("assets/icon.png")}
        my={"-32px"}
        mr={"-80px"}
        mb={[10, 0]}
        height={"190px"}
        zIndex="1"
        display={["none", "unset"]}
      />
      <Box mt={[0, "-20px"]} flex="1" textAlign={["left", "right"]}>
        <Label mb={1}>Balance</Label>
        <Card>
          <Stat
            type="btc"
            value={summary.totalBalance}
            size="xl"
            justify={["flex-start", "flex-end"]}
            mb={2}
          />
          <Stat
            type="usd"
            value={toBTC(summary.totalBalance) * price}
            color="gray.500"
            justify={["flex-start", "flex-end"]}
            mb={3}
          />
          <Stat
            size="md"
            justify={["flex-start", "flex-end"]}
            type="BTC/USD"
            color="gray.500"
            value={`~ ${format(price)}`}
          />
        </Card>
      </Box>
    </Flex>
  );
}

export default Header;
