import React from "react";
import {Box, Grid, Button} from "@chakra-ui/core";
import moment from "moment";
import Card from "components/Card";
import ChangeTag from "components/ChangeTag";
import {Label} from "components/Text";
import Stat from "components/Stat";
import {daysLeftInMonth} from "utils/date";
import useInvestor from "hooks/useInvestor";
import {
  Container,
  GridTable,
  GridTableRow,
  GridTableCell
} from "components/GridTable";

export function Summary({records, ...rest}) {
  const {getPerformanceSummary} = useInvestor();
  const {changePercentCompound} = getPerformanceSummary(records);
  const avg = changePercentCompound / records.length;
  const lastRecordDate = new Date(records?.[records.length - 1].date);
  const numberOfDaysLeft = daysLeftInMonth(lastRecordDate);

  const stats = [
    {
      label: "Return",
      type: "percent",
      value: changePercentCompound,
      infoText: "Compounded daily returns for the month"
    },
    {
      label: "Daily AVG",
      type: "percent",
      value: avg,
      infoText: "Avarage daily return for the month"
    },
    numberOfDaysLeft > 0 && {
      label: "Forecast",
      type: "percent",
      value: changePercentCompound + numberOfDaysLeft * avg,
      infoText:
        "Formula: number of days left in the month * daily avg + compounded daily returns for the month"
    }
  ].filter(Boolean);

  return (
    <Box
      p={0}
      py={[0, 0, 5]}
      px={[5, 5, 0]}
      bg="white"
      borderRadius="lg"
      {...rest}
    >
      <Grid
        templateColumns={["1fr", "1fr", `repeat(${stats.length}, 1fr)`]}
        gap="1px"
        bg="gray.100"
      >
        {stats.map(({label, type, value, infoText}) => (
          <Box bg="white" px={[0, 0, 5]} py={[5, 5, 0]} key={label}>
            <Label infoText={infoText} mb={1}>
              {label}
            </Label>
            <Stat type={type} value={value} />
          </Box>
        ))}
      </Grid>
    </Box>
  );
}

function Month({records, initialDaysVisible = 5, ...rest}) {
  const date = moment(records?.[0].date);
  const [daysVisible, setDaysVisible] = React.useState(initialDaysVisible);
  const visibleRecords = [...records].reverse().slice(0, daysVisible);
  return (
    <Box {...rest}>
      <Label mb={1}>{date.format("MMMM")}</Label>
      <Card p={0}>
        <Summary
          records={records}
          borderBottomColor="gray.100"
          borderBottomWidth="1px"
        />
        <Container borderRadius="lg">
          <GridTable templateColumns="1fr 1fr">
            <GridTableRow
              borderBottomColor="gray.100"
              borderBottomWidth="1px"
              position="sticky"
              top="0px"
              bg="white"
              px={5}
              pt={4}
              pb={3}
            >
              <Label textAlign="left">Return</Label>
              <Label textAlign="right">Date</Label>
            </GridTableRow>
            {visibleRecords.map((record, index) => (
              <GridTableRow
                key={record.date}
                bg={index % 2 !== 0 ? "white" : "gray.50"}
                px={5}
                py={3}
              >
                <GridTableCell textAlign="left">
                  <ChangeTag change={record.changePercent} />
                </GridTableCell>
                <GridTableCell>
                  {moment(record.date)
                    .utc()
                    .format("DD MMM YYYY")}
                </GridTableCell>
              </GridTableRow>
            ))}
          </GridTable>
        </Container>
        {visibleRecords.length < records.length && (
          <Button
            variant="ghost"
            width="100%"
            size="lg"
            onClick={() => setDaysVisible(records.length)}
            color="gray.500"
            borderTopColor="gray.100"
            borderTopWidth="1px"
          >
            Show all days
          </Button>
        )}
      </Card>
    </Box>
  );
}

export default Month;
