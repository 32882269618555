import moment from "moment";

export function daysInMonth(date = new Date()) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth() + 1,
    0
  ).getUTCDate();
}

export function daysLeftInMonth(date = new Date()) {
  const a = moment(date)
    .utc()
    .endOf("month");
  const b = moment().utc();
  return a.diff(b, "days");
}
