import React from "react";
import {Box, Text} from "@chakra-ui/core";
import Navbar from "./Navbar";

function Page({children}) {
  return (
    <Box minHeight="100vh" bg="gray.100" display="flex" flexDirection="column">
      <Navbar mb={[0, 6]} />
      <Box display="flex" flexDirection="column" py={10} flex="1">
        <Box maxWidth="680px" mx="auto" px={4} width="100%" flex="1">
          {children}
        </Box>
        <Box maxWidth="680px" mx="auto" mt={10} px={4}>
          <Text fontSize="14px" color="gray.400" mb={5}>
            As with any investment there is risk involved. Bitcoin is a volatile
            asset and fluctuates large percentage moves up and down. Invest only
            what you are prepared to loose. Do note that this is not a financial
            advise. This site is not affiliated with Amfeix corporate in any
            way.
          </Text>
          <Text fontSize="14px" color="gray.400">
            Made with{" "}
            <span role="img" aria-label="love">
              ❤️
            </span>{" "}
            by <a href="https://twitter.com/pepegombos">@pepegombos</a>
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default Page;
