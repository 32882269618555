import React from "react";
import {Stack} from "@chakra-ui/core";
import useInvestor from "hooks/useInvestor";
import {groupByMonths} from "utils/records";
import Header from "./Header";
import Summary from "./Summary";
import Month from "./Month";

function Fund() {
  const {fundPerformance} = useInvestor();
  const months = groupByMonths(fundPerformance);
  const latestMonth = months[months.length - 1];
  const previousMonths = [...months.slice(0, -1)].reverse();
  return (
    <Stack direction="column" spacing="60px">
      <Header />
      <Summary />
      <Month initialDaysVisible={latestMonth.length} records={latestMonth} />
      {previousMonths.map((records, index) => (
        <Month records={records} key={index} />
      ))}
    </Stack>
  );
}

export default Fund;
