import useFetch from "./useFetch";

function useBitcoinPrice(currency = "usd") {
  const {
    data: {
      data: {bpi}
    }
  } = useFetch("https://api.coindesk.com/v1/bpi/currentprice.json", {
    refreshInterval: 60 * 1000
  });
  const float = bpi?.[currency.toUpperCase()]?.rate_float || 0;
  return Math.round(float);
}

export default useBitcoinPrice;
