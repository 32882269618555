import styled from "@emotion/styled";
import Text from "components/Text";
import {Box, Grid} from "@chakra-ui/core";

export const Container = styled(Box)`
  overflow: auto;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const GridTable = styled(Grid)``;
GridTable.defaultProps = {
  width: "100%"
};

export const GridTableRow = styled(Box)`
  & > * {
    background: inherit;
    padding: inherit;
    position: inherit;
    top: inherit;
    border: inherit;
  }
`;

GridTableRow.defaultProps = {
  display: "contents"
};

export const GridTableCell = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
`;

GridTableCell.defaultProps = {
  textAlign: "right",
  color: "gray.600",
  as: "div"
};
