import React from "react";
import Storage from "utils/Storage";

const memoryStorage = new Storage();
const invalidating = {};
const errors = {};
const pending = {};

const useAsync = ({key, promise, args = [], cache = memoryStorage} = {}) => {
  const rerender = React.useState()[1];
  React.useEffect(() => {
    const unsubscribe = cache.on(key, () => {
      rerender({});
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [key]);

  const triggerPromise = React.useCallback(
    initial => {
      invalidating[key] = true;
      return promise(...args)
        .then(result => {
          errors[key] = null;
          cache.setItem(key, result);
        })
        .catch(e => {
          errors[key] = e;
        })
        .finally(() => {
          pending[key] = null;
          if (!initial) {
            invalidating[key] = false;
          }
        });
    },
    // eslint-disable-next-line
    [key]
  );

  if (!cache.getItem(key)) {
    // Pass initial true to prevent React.useEffect to invalidate promise for the first time.
    // This prevents fetching the same resource twice, first during initial render and second during first useEffect callback
    pending[key] = triggerPromise(true);
  }

  // Invalidate cache
  React.useEffect(() => {
    // Prevent multiple hooks revalidating the same key if process is already triggered
    if (!invalidating[key]) {
      triggerPromise();
    }
    // eslint-disable-next-line
  }, [key]);

  if (errors[key]) {
    throw errors[key];
  }

  if (pending[key]) {
    throw pending[key];
  }

  // Return cached result
  return {data: cache.getItem(key), revalidate: triggerPromise};
};

export default useAsync;
