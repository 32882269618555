import React from "react";
import styled from "@emotion/styled";
import {Text as Base, Tooltip, Flex, Icon} from "@chakra-ui/core";

const Text = styled(Base)``;
Text.defaultProps = {
  lineHeight: "1"
};
export default Text;

export const Uppercase = styled(Text)`
  text-transform: uppercase;
`;

export const Label = ({infoText, ...rest}) => {
  return infoText ? (
    <Flex align="baselint">
      <Uppercase {...rest} />
      <Tooltip label={infoText} placement="right-end">
        <Icon name="info" color="gray.300" ml={2} size="12px" />
      </Tooltip>
    </Flex>
  ) : (
    <Uppercase {...rest} />
  );
};

Label.defaultProps = {
  fontSize: "xs",
  color: "gray.500",
  fontWeight: 500
};
