import Amfeix from "amfeix";

const ethNodeUrl =
  "https://mainnet.infura.io/v3/efc3fa619c294bc194161b66d8f3585e";

const amfeix = new Amfeix({
  web3: ethNodeUrl,
});

export default amfeix;
